<template>
  <v-footer
    id="dashboard-core-footer"
    absolute
  >
    2019 &copy; Sharely
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
      links: [
        {
          href: '#',
          text: 'Creative Tim',
        },
        {
          href: '#',
          text: 'About Us',
        },
        {
          href: '#',
          text: 'Blog',
        },
        {
          href: '#',
          text: 'Licenses',
        },
      ],
    }),
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    background: #fff
    padding: 20px 25px
    display: flex
    justify-content: flex-end
    color: #74788d
    &.v-footer:not(.v-sheet--outlined)
      box-shadow: none
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
  .v-footer
    .row
      justify-content: flex-end
</style>
